import { combineReducers } from 'redux';
import UserReducer from './UserReducers';
import TicketReducer from './TicketReducers';

const rootReducer = combineReducers({
  UserReducer,
  TicketReducer,
});

export default rootReducer;
