import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { randomRgbBlueColor } from '../Utilities/AppUtilities';

function ApexPieChart({ data }) {
  const label = [];
  const series = [];

  data.forEach((sys) => {
    label.push(sys.ticketBySiteKey.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()));
    series.push(sys.ticketBySiteTotalTicket);
  });

  const options = {
    labels: label,
    theme: {
      monochrome: {
        enabled: false,
      },
    },
    colors: randomRgbBlueColor(data.length),
    legend: {
      position: 'top',
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name.length > 15 ? name.slice(0, 15) + '...' : name, val.toFixed(2) + '%'];
      },
      style: {
        colors: ['#84d3c0'],
      },
    },

    plotOptions: {
      pie: {
        dataLabels: {
          offset: -20,
        },
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="pie" height={500} />;
}

export default ApexPieChart;
