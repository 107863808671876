export const initialFilterValues = {
  callNo: '',
  description: '',
  priority: '',
  status: '',
  createdBy: '',
  assignedTo: '',
  createdDateRangeStart: '',
  createdDateRangeEnd: '',
};

const initialState = {
  ticketList: null,
  isFetching: false,
  isError: false,
  filter: initialFilterValues,
};

const TicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TICKET_LIST':
      return {
        ...state,
        ticketList: action.payload,
        isFetching: false,
        isError: false,
      };
    case 'UPDATE_FILTER':
      return {
        ...state,
        filter: action.payload,
      };
    case 'RESET_FILTER':
      return {
        ...state,
        filter: initialFilterValues,
      };
    default:
      return state;
  }
};

export default TicketReducer;
