import React, { useState, useEffect, useRef } from 'react';
import './NetworkHealth.css';
import { Button } from 'react-bootstrap';
import APIUrlConstants from '../../Config/APIUrlConstants';
import { fetchCall } from '../../Services/APIService';
import { apiMethods, gaEvents, httpStatusCode } from '../../Constants/TextConstants';
import useAnalyticsEventTracker from '../../Hooks/useAnalyticsEventTracker';
import { relDiff, userRoleId } from '../../Utilities/AppUtilities';

export default function NetworkHealth() {
  const [snackBar, setSnackBar] = useState(false);
  const { buttonTracker } = useAnalyticsEventTracker();
  const roledId = localStorage.getItem('roleId');
  const chatVideo = useRef();
  useEffect(() => {
    if (localStorage.getItem('contactSales') === 'true') {
      setSnackBar(true);
    }
  }, []);

  useEffect(() => {
    if (roledId === userRoleId.nonRemoteSmartUser) {
      const getVideo = JSON.parse(window.localStorage.getItem('chatVideo'));
      if (getVideo && getVideo.videoDuration !== getVideo.currentTime) {
        chatVideo.current.currentTime = getVideo.currentTime;
      }
      if (!getVideo || getVideo.currentTime !== 0) {
        chatVideo.current.play();
      }
      chatVideo.current.ontimeupdate = function () {
        if (chatVideo.current) {
          const videoDuration = chatVideo.current.duration;
          const diff = relDiff(videoDuration, chatVideo.current.currentTime);

          if (diff < 5) window.localStorage.setItem('chatVideo', JSON.stringify({ videoDuration, diff, currentTime: 0 }));
          else
            window.localStorage.setItem(
              'chatVideo',
              JSON.stringify({ videoDuration, diff, currentTime: chatVideo.current.currentTime }),
            );
        }
      };
    }
  }, []);

  const handleClick = async () => {
    const [statusCode] = await fetchCall(`${APIUrlConstants.CONTACT_SALES}/${localStorage.getItem('id')}`, apiMethods.POST, {});
    if (statusCode === httpStatusCode.SUCCESS) {
      setSnackBar(true);
      localStorage.setItem('contactSales', 'true');
    }
  };

  return (
    <div className="wrapperBase">
      <div className="wrapperCard">
        <div className="wrapperCard--header">
          <div className="titleHeader">
            <div className="info">
              <h6>Dashboard</h6>
            </div>
          </div>
          <div className="wrapperCard--body">
            <div className="videoWrapper">
              <video
                ref={chatVideo}
                width="100%"
                height="100%"
                controls
                src="https://d2rq951s6g3zbw.cloudfront.net/Upgrade%20Promo.mp4"
              >
                <source src="https://d2rq951s6g3zbw.cloudfront.net/Upgrade%20Promo.mp4" type="video/mp4" />
              </video>
            </div>
            {!snackBar && (
              <Button
                className="buttonPrimary mb-5"
                onClick={() => {
                  buttonTracker(gaEvents.CONTACT_SALES);
                  handleClick();
                }}
              >
                Contact Sales
              </Button>
            )}
          </div>
        </div>
        {snackBar && <div className="contactSalesText">Thank you for your interest. We will be contacting you soon!</div>}
      </div>
    </div>
  );
}
