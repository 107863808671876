import Values from 'values.js';
import { SIGNET_ADMIN, REMOTE_SMARTUSER, NON_REMOTE_SMARTUSER } from '../Config/Environment';

export const userRoleId = {
  nonRemoteSmartUser: NON_REMOTE_SMARTUSER,
  remoteSmartUser: REMOTE_SMARTUSER,
  signetAdmin: SIGNET_ADMIN,
};

export const roleId = localStorage.getItem('roleId');

export const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
export const token = localStorage.getItem('token');

export const randomColorForCharts = (count) => {
  const colors = [];
  for (let i = 0; i < count; i += 1) {
    colors.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
  }
  return colors;
};

export const randomRgbBlueColor = (count) => {
  const list = new Values('#16216c').all(1).slice(20, 120).reverse();
  const colors = [];
  for (let i = 0; i < count; i += 1) {
    if (i === 0) {
      colors.push(`rgb(${list[i].rgb.join(',')})`);
    } else if (i === count - 1) {
      colors.push(`rgb(${list[list.length - 1].rgb.join(',')})`);
    } else {
      colors.push(`rgb(${list[Math.round(100 / count) * i].rgb.join(',')})`);
    }
  }
  return colors;
};

export function relDiff(a, b) {
  return +Math.abs(100 - (b / a) * 100).toFixed(10);
}

export const downloadFile = (data, fileName) => {
  // Convert the Base64 string to a Blob
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/octet-stream' });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element and trigger a click event to download the file
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Clean up the temporary URL
  URL.revokeObjectURL(url);
};

export const formatFileSize = (kilobytes, decimalPoint) => {
  if (kilobytes === 0) return '0 KB';
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(kilobytes) / Math.log(k));
  return parseFloat((kilobytes / k ** i).toFixed(dm)) + ' ' + sizes[i];
};
