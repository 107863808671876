/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import APIUrlConstants from '../../Config/APIUrlConstants';
import { makeRequest } from '../../Services/APIService';
import { gaEvents, httpStatusCode } from '../../Constants/TextConstants';
import Loading from '../Widgets/Loading';
import './Tickets.css';
import { userRoleId } from '../../Utilities/AppUtilities';
import useAnalyticsEventTracker from '../../Hooks/useAnalyticsEventTracker';
import { useDispatch, useSelector } from 'react-redux';
import { updateTicketList } from '../../Redux/Actions/Actions';
import FilterModal from './FilterModal';

function Tickets() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tickets, setTickets] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);
  const closeAlert = () => setShowAlert(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { buttonTracker, linkTracker } = useAnalyticsEventTracker();
  const { filter } = useSelector((state) => state.TicketReducer);
  const [tableOrder, setTableOrder] = useState('');
  const [tableDataField, setTableDataField] = useState('');

  const filteredTickets = useMemo(
    () =>
      tickets.filter((ticket) => {
        const ticketDate = new Date(ticket.createdDate.split(' ')[0]);
        const startDate = new Date(filter?.createdDateRangeStart);
        const endDate = new Date(filter?.createdDateRangeEnd);

        return (
          (filter?.callNo === '' || ticket.callNo.includes(filter?.callNo)) &&
          (filter?.description === '' || ticket.description.toLowerCase().includes(filter?.description.toLowerCase())) &&
          (filter?.priority === '' || ticket.priority === filter?.priority) &&
          (filter?.status === '' || ticket.status === filter?.status) &&
          (filter?.createdBy === '' || ticket.createdBy.includes(filter?.createdBy)) &&
          (filter?.assignedTo === '' || ticket.assignedTo.includes(filter?.assignedTo)) &&
          (filter?.createdDateRangeStart === '' || ticketDate >= startDate) &&
          (filter?.createdDateRangeEnd === '' || ticketDate <= endDate)
        );
      }),
    [tickets, filter],
  );

  const fetchAllUserDetails = async () => {
    setIsLoading(true);
    const { 0: statusCode, 1: data } = await makeRequest(
      APIUrlConstants.TICKETS_LIST + `?customerNo=${localStorage.getItem('orgNo')}`,
    );
    if (statusCode === httpStatusCode.SUCCESS) {
      setIsLoading(false);
      setTickets(data.data ?? []);
      dispatch(updateTicketList(data.data));
    } else {
      setShowAlert(true);
      setError(true);
      setAlertMessage(data?.message || 'Failed to fetch tickets');
      setIsLoading(false);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };
  const handleClick = (ticketId) => {
    buttonTracker(gaEvents.NAVIGATE_EDIT_TICKET);
    dispatch(updateTicketList(filteredTickets));
    navigate(`/ticket/edit/${ticketId}`);
  };

  const actionBtn = (_row, cell, _rowIndex) => (
    <div className="actionBox d-flex align-items-center">
      {cell.callerEmail === localStorage.getItem('email') && (
        <Button variant="link" id={cell.ticketNo} onClick={() => handleClick(cell.ticketNo)}>
          <img src={process.env.REACT_APP_PUBLIC_URL + 'images/users/edit.svg'} id={cell.ticketNo} alt="Edit" />
        </Button>
      )}
    </div>
  );

  const ticketView = (_row, cell, _rowIndex) => (
    <div className="d-flex align-items-center">
      <Button
        variant="link"
        id={cell.ticketNo}
        onClick={() => {
          linkTracker(gaEvents.NAVIGATE_VIEW_TICKET);
          dispatch(updateTicketList(filteredTickets));
          navigate(`/ticket/view/${cell.ticketNo}`);
        }}
      >
        {cell.callNo}
      </Button>
    </div>
  );

  const descriptionText = (_row, cell, _rowIndex) => <p className="truncate">{cell.description}</p>;

  useEffect(() => {
    if (filteredTickets.length && tableOrder && tableDataField) {
      const sortedData = filteredTickets.sort((first, second) =>
        tableOrder === 'asc'
          ? first[tableDataField].localeCompare(second[tableDataField])
          : second[tableDataField].localeCompare(first[tableDataField]),
      );
      dispatch(updateTicketList(sortedData));
    }
  }, [tableOrder, tableDataField]);

  const customSort = (a, b, order, dataField, rowA, rowB) => {
    setTableOrder(order);
    setTableDataField(dataField);
    return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
  };

  const columns = [
    {
      dataField: 'ticketNo',
      text: 'Ticket#',
      formatter: ticketView,
      sort: true,
      sortFunc: customSort,
      style: { minWidth: '100px' },
    },
    {
      dataField: 'description',
      text: 'Description',
      formatter: descriptionText,
      sort: true,
      sortFunc: customSort,
      style: { minWidth: '130px' },
    },
    {
      dataField: 'priority',
      text: 'Priority',
      sort: true,
      sortFunc: customSort,
      style: { minWidth: '100px' },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      sortFunc: customSort,
      style: { minWidth: '100px' },
    },
    {
      dataField: 'createdBy',
      text: 'Created By',
      sort: true,
      sortFunc: customSort,
      style: { minWidth: '130px' },
    },
    {
      dataField: 'createdDate',
      text: 'Created Date',
      sort: true,
      sortFunc: customSort,
      style: { minWidth: '140px' },
    },
    {
      dataField: 'assignedTo',
      text: 'Assignee',
      sort: true,
      sortFunc: customSort,
      style: { minWidth: '120px' },
    },
    {
      dataField: 'Action',
      text: 'Edit',
      formatter: actionBtn,
      id: 'edit',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const fcFrame = document.getElementById('fc_frame');
      fcFrame.style.visibility = 'hidden';
      if (fcFrame.style.visibility === 'hidden') {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('roleId') === userRoleId.signetAdmin) {
      navigate('/');
    } else {
      fetchAllUserDetails();
    }
  }, []);

  const emptyDataMessage = () =>
    !isLoading ? (
      <h6 className="text-center text-bold m-0 p-0">No records found</h6>
    ) : (
      <h6 className="text-center text-bold m-0 p-0">Loading ...</h6>
    );

  return (
    <div className="wrapperBase">
      <div className="tabelBase" data-test-id="usertable">
        {isLoading && <Loading />}
        <div className="titleHeader d-flex align-items-center justify-content-between">
          <div className="info">
            <h6>Tickets</h6>
          </div>
          <div className="headerAction d-flex align-items-center">
            <FilterModal tickets={tickets} />
            <Button
              className="buttonPrimary"
              onClick={() => {
                buttonTracker(gaEvents.NAVIGATE_ADD_TICKET);
                navigate(`/ticket/add`);
              }}
            >
              <img src={process.env.REACT_APP_PUBLIC_URL + 'images/users/plus.svg'} alt="" /> Create Ticket
            </Button>
          </div>
        </div>
        <ToolkitProvider keyField="ticketNo" data={filteredTickets} columns={columns}>
          {(props) => (
            <div className="tableBaseBox ticketTable">
              <BootstrapTable
                {...props.baseProps}
                pagination={filteredTickets?.length > 10 ? paginationFactory({ sizePerPage: 10 }) : null}
                noDataIndication={emptyDataMessage}
              />
            </div>
          )}
        </ToolkitProvider>

        {showAlert && (
          <Alert variant={!error ? 'success' : 'danger'} className="alertWrapper" onClose={closeAlert} dismissible>
            <Alert.Heading>{alertMessage}</Alert.Heading>
          </Alert>
        )}
      </div>
    </div>
  );
}

export default Tickets;
