import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { fetchCall, makeRequest } from '../../Services/APIService';
import APIUrlConstants from '../../Config/APIUrlConstants';
import Loading from '../Widgets/Loading';
import Alerts from '../Widgets/Alerts';
import { useNavigate, useParams } from 'react-router-dom';
import { apiMethods, gaEvents, httpStatusCode } from '../../Constants/TextConstants';
import useAnalyticsEventTracker from '../../Hooks/useAnalyticsEventTracker';
import moment from 'moment-timezone';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { downloadFile, formatFileSize } from '../../Utilities/AppUtilities';
import { useSelector } from 'react-redux';
import { getNextPrevTicketNo } from './ViewTicket';

export default function AddTicket() {
  const navigate = useNavigate();
  const { ticketList } = useSelector((state) => state.TicketReducer);
  const [isLoading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [Priority, setPriority] = useState([]);
  const [ProblemCode, setProblemCode] = useState([]);
  const [systemType, setSystemType] = useState([]);
  const [PostObject, setPostObject] = useState({
    assignedTo: '',
    customerId: '',
    site: '',
    createdBy: '',
    createdDate: '',
    lastModifiedDate: '',
    phoneNumber: '',
    status: 'ACTIVE',
    requestType: 'NOC',
    problem: 'System Trouble',
    description: '',
    callerEmail: '',
    priority: '3 - Medium',
    solutionProvided: '',
    ticketNo: '',
    callNo: '',
    systemType: 'SEC',
  });
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [options, setOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertVarient, setAlertVarient] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const { id } = useParams();
  const [validated, setValidated] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const { buttonTracker } = useAnalyticsEventTracker();
  const [noApiError, setNoApiError] = useState(true);
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const solutionProvidedRef = useRef();
  const descriptionRef = useRef();
  const additionalDetailsRef = useRef();
  const [ticketDocuments, setTicketDocuments] = useState([]);
  const maxLength = 2;
  const inputFileRef = useRef(null);
  const supportedFileFormats = ['.docx', '.doc', '.xlsx', '.xls', 'pptx', 'ppt', '.pdf', '.txt', '.png', '.gif', '.jpg', '.jpeg'];
  const nextPrevTicket = useMemo(() => getNextPrevTicketNo(ticketList, id), [ticketList, id]);

  const showAlertToaster = (variant, message) => {
    setShowAlert(true);
    setAlertVarient(variant);
    setAlertMessage(message);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  useEffect(() => {
    if (solutionProvidedRef && solutionProvidedRef.current) {
      solutionProvidedRef.current.style.height = '0px';
      const taHeight = solutionProvidedRef.current.scrollHeight;
      solutionProvidedRef.current.style.height = (taHeight > 150 ? 150 : taHeight + 5) + 'px';
    }
  }, [PostObject.solutionProvided]);
  useEffect(() => {
    if (descriptionRef && descriptionRef.current) {
      descriptionRef.current.style.height = '0px';
      const taHeight = descriptionRef.current.scrollHeight;
      descriptionRef.current.style.height = (taHeight > 150 ? 150 : taHeight + 5) + 'px';
    }
  }, [PostObject.description]);
  useEffect(() => {
    if (additionalDetailsRef && additionalDetailsRef.current) {
      additionalDetailsRef.current.style.height = '0px';
      const taHeight = additionalDetailsRef.current.scrollHeight;
      additionalDetailsRef.current.style.height = taHeight + 5 + 'px';
    }
  }, [additionalDetails]);

  const getDate = () => {
    const format = 'YYYY-MM-DD HH:mm';
    const date = moment.utc(new Date()).tz('America/New_York').format(format);
    return date;
  };

  const fetchPromise = async () => {
    const optionArray = [];
    const fetchSitelist = await makeRequest(`${APIUrlConstants.LIST_SITES}?customerNo=${localStorage.getItem('orgNo')}`);
    const fetchPriority = await makeRequest(APIUrlConstants.LIST_PRIORITY);
    const fetchProblemcode = await makeRequest(APIUrlConstants.LIST_PROBLEM_CODE);
    const fetchSystemType = await makeRequest(APIUrlConstants.LIST_SYSTEM_TYPE);
    let resolvedArr;
    if (id) {
      const fetchTicketView = await makeRequest(
        `${APIUrlConstants.VIEW_TICKET}/${id}?customerNo=${localStorage.getItem('orgNo')}`,
      );
      resolvedArr = await Promise.all([fetchSitelist, fetchPriority, fetchProblemcode, fetchTicketView]);
    } else {
      resolvedArr = await Promise.all([fetchSitelist, fetchPriority, fetchProblemcode, fetchSystemType]);
    }
    resolvedArr.forEach((i) => {
      if (i[0] !== httpStatusCode.SUCCESS) {
        setNoApiError(false);
        setApiErrorMsg(i[1].message ?? 'There was a problem with our ticketing service. Please try again later');
        setShowAlert(true);
        setAlertVarient('danger');
        setAlertMessage(i[1].message ?? 'There was a problem with our ticketing service. Please try again later');
        setTimeout(() => {
          setShowAlert(false);
          navigate('/tickets');
        }, 5000);
      }
    });
    if (resolvedArr && resolvedArr.every((i) => i[0] === httpStatusCode.SUCCESS)) {
      const { 0: Sitelist, 1: PriorityList, 2: ProblemCodeList, 3: fetchTicket } = resolvedArr;
      Sitelist[1]?.data.length > 0 &&
        Sitelist[1]?.data.forEach((i) => {
          optionArray.push({ value: i.siteNo, label: i.siteName });
        });
      setOptions([{ label: 'Search for Site Name', value: '' }, ...optionArray]);
      setPriority(PriorityList[1]?.data);
      setProblemCode(ProblemCodeList[1]?.data);
      if (!id) {
        const { 3: systemTypeList } = resolvedArr;
        setSystemType(systemTypeList[1]?.data);
      }
      setLoading(false);
      if (id) {
        setOptions(optionArray);
        setPostObject((prev) => {
          const Current = { ...prev };
          Current.requestType = fetchTicket[1]?.data[0].requestType;
          Current.description = fetchTicket[1]?.data[0].description;
          Current.phoneNumber = fetchTicket[1]?.data[0].phoneNumber;
          Current.priority = fetchTicket[1]?.data[0].priority;
          Current.status = fetchTicket[1]?.data[0].status;
          Current.callerEmail = fetchTicket[1]?.data[0].callerEmail;
          Current.solutionProvided = fetchTicket[1]?.data[0].solutionProvided;
          Current.problem = fetchTicket[1]?.data[0].problem;
          Current.ticketNo = fetchTicket[1]?.data[0].ticketNo;
          Current.createdBy = fetchTicket[1]?.data[0].createdBy;
          Current.createdDate = fetchTicket[1]?.data[0].createdDate;
          Current.lastModifiedDate = fetchTicket[1]?.data[0].lastModifiedDate;
          Current.assignedTo = fetchTicket[1]?.data[0].assignedTo;
          Current.callNo = fetchTicket[1]?.data[0].callNo;
          Current.systemType = fetchTicket[1]?.data[0].systemType;
          Current.site = Sitelist[1]?.data.filter((i) => i.siteName === fetchTicket[1]?.data[0].site)[0].siteNo;
          return Current;
        });
        setTicketDocuments(fetchTicket[1]?.data[0].documentList ?? []);
        setSelectedValue(Sitelist[1]?.data.filter((i) => i.siteName === fetchTicket[1]?.data[0].site)[0].siteNo);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setPostObject((prev) => {
      const Current = { ...prev };
      Current.customerId = localStorage.getItem('orgNo');
      Current.createdBy = localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
      Current.callerEmail = localStorage.getItem('email');
      Current.phoneNumber = localStorage.getItem('mobile');
      return Current;
    });
    fetchPromise();
  }, [id]);

  const saveTicket = async () => {
    setSaveLoading(true);
    let ticketObject = { ...PostObject };
    if (id) {
      if (additionalDetails && additionalDetails.trim().length > 0) {
        ticketObject = {
          ...PostObject,
          ticketNo: id,
          description: `${PostObject.description} \n \n ${getDate()} \n \n ${additionalDetails}`,
        };
      } else {
        ticketObject = { ...PostObject, ticketNo: id };
      }
      delete ticketObject.assignedTo;
      delete ticketObject.solutionProvided;
      delete ticketObject.createdDate;
      buttonTracker(gaEvents.UPDATE_TICKET_DETAILS);
    } else {
      buttonTracker(gaEvents.CREATE_NEW_TICKET);
    }
    if (PostObject.description && PostObject.site && PostObject.callerEmail === localStorage.getItem('email') && noApiError) {
      const { 0: status, 1: data } = await fetchCall(APIUrlConstants.CREATE_TICKET, apiMethods.POST, ticketObject);
      const statusCode = status;
      const responseData = data;

      if (statusCode === httpStatusCode.SUCCESS) {
        setSaveLoading(false);
        // navigate(`/ticket/view/${responseData.data.ticketNo}`);
        navigate(id ? `/ticket/view/${id}` : '/tickets');
      } else {
        setShowAlert(true);
        setAlertVarient('danger');
        setAlertMessage(responseData.message ?? 'something went wrong ');
        setSaveLoading(false);
        setTimeout(() => {
          setShowAlert(false);
          navigate('/tickets');
        }, 5000);
      }
    } else if (PostObject.callerEmail !== localStorage.getItem('email')) {
      setShowAlert(true);
      setAlertVarient('danger');
      setAlertMessage('You are not Authorized');
      setSaveLoading(false);
      setTimeout(() => {
        setShowAlert(false);
        navigate('/tickets');
      }, 5000);
    } else if (noApiError !== true) {
      setShowAlert(true);
      setAlertVarient('danger');
      setAlertMessage(apiErrorMsg);
      setSaveLoading(false);
      setTimeout(() => {
        setShowAlert(false);
        navigate('/tickets');
      }, 5000);
    } else {
      setValidated(true);
      setSaveLoading(false);
    }
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    setPostObject((prev) => {
      const Current = { ...prev };
      Current.site = e.target.value;
      return Current;
    });
  };

  const onBtnClick = () => {
    if (inputFileRef.current != null) {
      inputFileRef.current.click();
    }
  };

  const onFileChangeCapture = async (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const fileExtension = `.${file.name.split('.').pop()}`;
    if (!supportedFileFormats.includes(fileExtension.toLowerCase())) {
      showAlertToaster('danger', 'Unsupported file format.');
      e.target.value = '';
      return;
    }
    const maxSize = maxLength * 1024 * 1024;
    if (file && file.size > maxSize) {
      showAlertToaster('danger', `File size exceeds the limit (${maxLength}MB). Please select a smaller file.`);
      e.target.value = '';
      return;
    }
    setSaveLoading(true);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const base64String = event.target.result.split(',')[1];
      const orgNo = localStorage.getItem('orgNo');
      const orgName = localStorage.getItem('orgName');
      const documentObject = {
        ticketNo: id,
        orgNo,
        orgName,
        fileName: file.name,
        size: Math.round(file.size / 1024),
        encodedDoc: base64String,
        type: file.type,
      };
      try {
        const [status, data] = await fetchCall(`${APIUrlConstants.ADD_DOCUMENT_TO_TICKET}?customerNo=${orgNo}`, apiMethods.POST, [
          documentObject,
        ]);
        const documentResponse = data.data[0];
        if (status === httpStatusCode.SUCCESS) {
          const savedDocument = {
            ...documentObject,
            documentNo: documentResponse.documentNo,
            title: documentResponse.title,
            createdDate: getDate(),
          };
          setTicketDocuments([...ticketDocuments, savedDocument]);
          showAlertToaster('success', 'Document saved successfully');
        } else {
          showAlertToaster('danger', 'Failed to upload, something went wrong!');
        }
      } catch (error) {
        showAlertToaster('danger', 'Failed to upload, something went wrong!');
      }
      setSaveLoading(false);
    };
    reader.readAsDataURL(file);
    e.target.value = '';
  };

  const handleDownload = (documentNo) => {
    const document = ticketDocuments.find((a) => a.documentNo === documentNo);
    const { fileName, encodedDoc } = document;
    downloadFile(encodedDoc, fileName);
  };

  const actionBtn = (_row, cell, _rowIndex) => (
    <div className="actionBox d-flex align-items-center">
      <Button variant="link" id={cell.ticketNo} onClick={() => handleDownload(cell.documentNo)}>
        <img src={process.env.REACT_APP_PUBLIC_URL + 'images/users/download.svg'} id={cell.documentNo} alt="Download" />
      </Button>
    </div>
  );

  const fileSizeText = (_row, cell, _rowIndex) => <p>{formatFileSize(cell.size)}</p>;

  const emptyDataMessage = () =>
    !saveLoading ? (
      <h6 className="text-center text-bold m-0 p-0">No document found</h6>
    ) : (
      <h6 className="text-center text-bold m-0 p-0">Loading ...</h6>
    );

  const columns = [
    {
      dataField: 'fileName',
      text: 'Name',
    },
    {
      dataField: 'size',
      text: 'Size',
      formatter: fileSizeText,
    },
    {
      dataField: 'type',
      text: 'Type',
    },
    // {
    //   dataField: 'createdDate',
    //   text: 'Date Created',
    // },
    {
      dataField: 'Download',
      text: 'Download',
      formatter: actionBtn,
      id: 'download',
    },
  ];

  return (
    <div className="wrapperBase">
      {showAlert && (
        <Alerts
          variant={alertVarient}
          onClose={() => {
            setShowAlert(false);
          }}
          alertshow={alertMessage}
        />
      )}
      {saveLoading && <Loading />}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="titleHeader d-flex align-items-center justify-content-between">
            <div className="info d-flex align-items-center flexGap">
              <h6>{id ? `Edit Ticket # : ${PostObject.callNo}` : 'Add Ticket'}</h6>
              {nextPrevTicket.prevTicket !== null || nextPrevTicket.nextTicket !== null ? (
                <>
                  <Button
                    className="blueBtn text-center"
                    disabled={nextPrevTicket.prevTicket === null}
                    onClick={() => navigate(`/ticket/view/${nextPrevTicket.prevTicket}`)}
                  >
                    <img
                      width={20}
                      height={20}
                      src={process.env.REACT_APP_PUBLIC_URL + 'images/skip-backward.svg'}
                      alt="Previous Ticket"
                    />
                  </Button>
                  <Button
                    className="blueBtn text-center"
                    disabled={nextPrevTicket.nextTicket === null}
                    onClick={() => navigate(`/ticket/view/${nextPrevTicket.nextTicket}`)}
                  >
                    <img
                      width={20}
                      height={20}
                      src={process.env.REACT_APP_PUBLIC_URL + 'images/skip-forward.svg'}
                      alt="Next Ticket"
                    />
                  </Button>
                </>
              ) : null}
            </div>
          </div>

          <div className="wrapperBase">
            <Form noValidate validated={validated}>
              <Form.Group className="mb-3 input-group">
                <div className="input-container col">
                  <Form.Label>Description {!id && <span className="requiredTxt">*</span>}</Form.Label>
                  {!id && (
                    <Form.Control
                      as="textarea"
                      className="width-95"
                      placeholder="Describe the issue or request"
                      required
                      name="description"
                      onChange={(e) => {
                        setPostObject((prev) => {
                          const Current = { ...prev };
                          Current.description = e.target.value;
                          return Current;
                        });
                      }}
                      value={PostObject.description}
                    />
                  )}
                  {id && (
                    <Form.Control
                      ref={descriptionRef}
                      rows={2}
                      as="textarea"
                      className="width-95"
                      placeholder="Describe the issue or request"
                      name="description"
                      value={PostObject.description}
                      disabled
                      readOnly
                    />
                  )}
                  <Form.Control.Feedback type="invalid">Description is required</Form.Control.Feedback>
                </div>
              </Form.Group>
              {id && (
                <Form.Group className="mb-3 input-group">
                  <div className="input-container col">
                    <Form.Label>Additional Details</Form.Label>
                    <Form.Control
                      ref={additionalDetailsRef}
                      rows={2}
                      as="textarea"
                      className="width-95"
                      placeholder="Enter additional details"
                      name="additionalDetails"
                      onChange={(e) => setAdditionalDetails(e.target.value)}
                      value={additionalDetails}
                    />
                  </div>
                </Form.Group>
              )}
              <div className="mb-3 input-group">
                <Form.Group controlId="siteName" className="input-container col-6">
                  <Form.Label>
                    Site Name <span className="requiredTxt">*</span>
                  </Form.Label>
                  <Form.Select
                    className={!selectedValue ? 'text-gray width-90' : 'width-90'}
                    required
                    data-testid="siteName"
                    onChange={handleChange}
                    placeholder="Search for Site Name"
                    value={selectedValue}
                  >
                    {options.map((i) => (
                      <option className="text-black" key={i.value} value={i.value}>
                        {i.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Site Name is required</Form.Control.Feedback>
                </Form.Group>
                {!id && (
                  <Form.Group controlId="priority" className="input-container col-6">
                    <Form.Label>Priority {!id && <span className="requiredTxt">*</span>}</Form.Label>
                    <Form.Select
                      className="width-90"
                      data-testid="priority"
                      onChange={(e) => {
                        setPostObject((prev) => {
                          const Current = { ...prev };
                          Current.priority = e.target.value;
                          return Current;
                        });
                      }}
                      value={PostObject.priority}
                      disabled={id}
                    >
                      {Priority.map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                {id && (
                  <Form.Group controlId="priority" className="input-container col-6">
                    <Form.Label>Priority</Form.Label>
                    <Form.Control
                      data-testid="priority"
                      placeholder="Priority"
                      type="text"
                      className="width-90"
                      value={PostObject.priority}
                      disabled
                    />
                  </Form.Group>
                )}
              </div>
              <Form.Group className="mb-3 input-group">
                <div className="input-container col-6">
                  <Form.Label>Problem Code {!id && <span className="requiredTxt">*</span>}</Form.Label>
                  <Form.Select
                    className="width-90"
                    data-testid="problemCode"
                    onChange={(e) => {
                      setPostObject((prev) => {
                        const Current = { ...prev };
                        Current.problem = e.target.value;
                        return Current;
                      });
                    }}
                    value={PostObject.problem}
                    disabled={id}
                  >
                    {ProblemCode.map((i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                {!id && (
                  <div className="input-container col-6">
                    <Form.Label>System Type {!id && <span className="requiredTxt">*</span>}</Form.Label>
                    <Form.Select
                      className="width-90"
                      data-testid="systemType"
                      onChange={(e) => {
                        setPostObject((prev) => {
                          const Current = { ...prev };
                          Current.systemType = e.target.value;
                          return Current;
                        });
                      }}
                      value={PostObject.systemType}
                      disabled={id}
                    >
                      {systemType.map((value) => (
                        <option key={value.id} value={value.q360Systemtype}>
                          {value.mobAppSystemType}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                )}
                {id && (
                  <div className="input-container col-6">
                    <Form.Label>Created Date</Form.Label>
                    <Form.Control
                      placeholder="Created Date"
                      type="text"
                      className="width-90"
                      value={PostObject.createdDate}
                      disabled
                    />
                  </div>
                )}
              </Form.Group>
              {id && (
                <Form.Group className="mb-3 input-group">
                  <div className="input-container col-6">
                    <Form.Label>Assigned To</Form.Label>
                    <Form.Control
                      placeholder="Assigned To"
                      type="text"
                      className="width-90"
                      value={PostObject.assignedTo}
                      disabled
                    />
                  </div>
                  <div className="input-container col-6">
                    <Form.Label>Solution Provided</Form.Label>
                    <Form.Control
                      ref={solutionProvidedRef}
                      rows={1}
                      placeholder="Solution Provided"
                      as="textarea"
                      className="width-90"
                      value={PostObject.solutionProvided}
                      disabled
                      readOnly
                    />
                  </div>
                </Form.Group>
              )}
              {id && (
                <Form.Group className="mb-3 input-group">
                  <div className="input-container col-6">
                    <Form.Label>Created By</Form.Label>
                    <Form.Control
                      placeholder="Created By"
                      type="text"
                      className="width-90"
                      value={PostObject.createdBy}
                      disabled
                    />
                  </div>
                  <div className="input-container col-6">
                    <Form.Label>Status</Form.Label>
                    <Form.Control placeholder="Status" type="text" className="width-90" value={PostObject.status} disabled />
                  </div>
                </Form.Group>
              )}
              {id && (
                <Form.Group className="mb-3 input-group">
                  <div className="input-container col-6">
                    <Form.Label>Last Modified Date</Form.Label>
                    <Form.Control
                      placeholder="Last Modified Date"
                      type="text"
                      className="width-90"
                      value={PostObject.lastModifiedDate}
                      disabled
                    />
                  </div>
                </Form.Group>
              )}
            </Form>
            <div className="d-flex justify-content-md-start justify-content-sm-center justify-content-center editAction">
              <input
                className="buttonDefault text-center minHeight45"
                type="submit"
                onClick={() => {
                  buttonTracker(gaEvents.NAVIGATE_TICKETS_LIST);
                  navigate('/tickets');
                }}
                value="Cancel"
              />
              <Button
                className="buttonPrimary text-center"
                onClick={() => {
                  if (PostObject.description.trim().length > 0) {
                    saveTicket();
                  } else {
                    setPostObject((prev) => {
                      const Current = { ...prev };
                      Current.description = '';
                      return Current;
                    });
                    setValidated(true);
                  }
                }}
                disabled={PostObject.callerEmail !== localStorage.getItem('email')}
              >
                {id ? 'Update' : 'Create'}
              </Button>
            </div>
            {id ? (
              <>
                <div className="d-flex justify-content-between align-items-center my-4">
                  <h4 className="mb-0 focusable-div">Document List</h4>

                  <div style={{ width: '1px', height: '1px', backgroundColor: 'red', overflow: 'hidden' }}>
                    <input name="FileUpload" type="file" ref={inputFileRef} onChangeCapture={onFileChangeCapture} />
                  </div>
                  <div className="d-flex justify-content-between align-items-center gap-2">
                    <span style={{ fontSize: '14px' }}>( Max file size {maxLength} MB )</span>
                    <Button
                      className="uploadButton buttonPrimary text-center"
                      onClick={onBtnClick}
                      disabled={PostObject.callerEmail !== localStorage.getItem('email')}
                    >
                      Upload File
                    </Button>
                  </div>
                </div>
                <div className="tabelBase" data-test-id="usertable">
                  <ToolkitProvider keyField="DocumentNo" data={ticketDocuments} columns={columns}>
                    {(props) => (
                      <div className="tableBaseBox ticketTable">
                        <BootstrapTable
                          {...props.baseProps}
                          pagination={ticketDocuments?.length > 10 ? paginationFactory({ sizePerPage: 10 }) : null}
                          noDataIndication={emptyDataMessage}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
