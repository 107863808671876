import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ProblemCodeChart({ data }) {
  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
        labels: {
          usePointStyle: true,
          boxWidth: 8,
        },
      },
      title: {
        display: true,
        position: 'left',
        text: 'Number of Tickets',
      },
    },
  };
  const labels = [];
  const values = [];
  data.forEach((sys) => {
    if (sys.key) {
      labels.push(sys.key.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()));
      values.push(parseFloat(sys.problem).toFixed());
    }
  });

  const dataValues = {
    labels,
    datasets: [
      {
        label: 'No of Tickets',
        data: values,
        backgroundColor: '#16216c',
        barPercentage: 0.3,
      },
    ],
  };

  return <Bar options={options} data={dataValues} />;
}

export default ProblemCodeChart;
