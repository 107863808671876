import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { initialFilterValues } from '../../Redux/Reducers/TicketReducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilter } from '../../Redux/Actions/Actions';
import { isEqual } from 'lodash';
import '../Users/Users.css';

function FilterModal({ tickets }) {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const { filter } = useSelector((state) => state.TicketReducer);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: filter,
  });

  const allPriority = Array.from(new Set(tickets.map((ticket) => ticket.priority))).sort((a, b) => Number(a) - Number(b));
  const allStatus = Array.from(new Set(tickets.map((ticket) => ticket.status))).sort();
  const allCreatedBy = Array.from(new Set(tickets.map((ticket) => ticket.createdBy))).sort();
  const allAssignedTo = Array.from(new Set(tickets.map((ticket) => ticket.assignedTo))).sort();

  const watchStartDate = watch('createdDateRangeStart');
  const watchEndDate = watch('createdDateRangeEnd');

  const onFilterClose = () => setShowFilter(false);

  const onSubmit = (filterValues) => {
    dispatch(updateFilter(filterValues));
    onFilterClose();
  };

  const onReset = () => {
    reset(initialFilterValues);
    dispatch(updateFilter(initialFilterValues));
  };

  return (
    <>
      {!isEqual(filter, initialFilterValues) ? (
        <Button variant="link" onClick={() => onReset()}>
          Reset Filter
        </Button>
      ) : null}
      {tickets.length ? (
        <Button className="buttonPrimary" onClick={() => setShowFilter(true)}>
          Filter Ticket
        </Button>
      ) : null}
      <Modal size="lg" className="userModal" centered show={showFilter} onHide={onFilterClose}>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
          <Modal.Header closeButton>
            <Modal.Title>Ticket Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Ticket No</Form.Label>
                  <Form.Control type="number" autoFocus {...register('callNo')} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" autoFocus {...register('description')} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Priority</Form.Label>
                  <Form.Select autoFocus {...register('priority')}>
                    <option value="">--Select a priority--</option>
                    {allPriority.map((priority) => (
                      <option key={priority} value={priority}>
                        {priority}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select autoFocus {...register('status')}>
                    <option value="">--Select a status--</option>
                    {allStatus.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Created By</Form.Label>
                  <Form.Select autoFocus {...register('createdBy')}>
                    <option value="">--Select a created by--</option>
                    {allCreatedBy.map((createdBy) =>
                      createdBy ? (
                        <option key={createdBy} value={createdBy}>
                          {createdBy}
                        </option>
                      ) : null,
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Assignee</Form.Label>
                  <Form.Select autoFocus {...register('assignedTo')}>
                    <option value="">--Select a assignee--</option>
                    {allAssignedTo.map((assignedTo) =>
                      assignedTo ? (
                        <option key={assignedTo} value={assignedTo}>
                          {assignedTo}
                        </option>
                      ) : null,
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Created Date Range Start</Form.Label>
                  <Form.Control type="date" {...register('createdDateRangeStart')} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Created Date Range End</Form.Label>
                  <Form.Control
                    type="date"
                    isInvalid={errors?.createdDateRangeEnd && watchEndDate && watchStartDate}
                    {...register('createdDateRangeEnd', {
                      validate: (value) =>
                        !(value && watchStartDate) ||
                        value === watchStartDate ||
                        new Date(value).getTime() > new Date(watchStartDate).getTime(),
                    })}
                  />
                  <Form.Control.Feedback type="invalid">End date should not be before start date</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="p-3">
            <Button variant="secondary" onClick={() => reset()}>
              Reset
            </Button>
            <Button variant="primary" className="blueBtn" type="submit">
              Filter
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default FilterModal;
